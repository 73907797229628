<template>
  <div class="about">
    <div class="about-container">
      <div class="about-info">
        <h2 class="text-center">WHO WE ARE</h2>
      </div>
      <div class="about-info">
        <p class="p-no-mar-btm">
          {{mainInfo.details1}}
        </p>
      </div>
      <div class="about-info">
        <p class="p-no-mar-btm">
          {{mainInfo.details2}}
          <br><br>
          {{mainInfo.details3}}
          <br><br>
        </p>
        <h3>
          Download our company profile here: <br>
          <a class="download-a" target="_blank" href="https://staging.ploutus.com/profile/Ploutus-Holdings-Profile.pdf"><v-icon medium color="red darken-2">mdi-file-pdf-outline</v-icon>Download Here</a>
        </h3>
      </div>
      <div class="about-info">
        <h2 class="text-center">Board of Directors</h2>
      </div>
      <div class="about-flex-box-holder">
        <div class="about-card" v-for="(people) in board" :key="people.id">
          <div class="about-img-holder">
            <img  :src="require(`../assets/${people.img}`)" class="about-card-img" :alt="`${people.name}`">
          </div>
          <div class="text-holder">
            <h3 class="mar-top-15">{{people.name}}</h3>
            <h5 class="">{{people.designation}}</h5>
          </div>
          <div class="btn-dialog-holder mar-top-15">
            
              <v-dialog
                v-model="people.dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    
                    v-bind="attrs"
                    v-on="on"
                    class="pop-btn"
                  >
                    Profile
                  </v-btn>
                </template>
                <v-card>
                  <div class="pop-flex">
                    <div>
                      <img :src="require(`../assets/${people.img}`)" :alt="`${people.name}`">
                    </div>
                    <div>
                      <v-card-title>
                        <span class="text-h4"><b>{{people.name}}</b></span>
                      </v-card-title>
                      <v-card-title>
                        <span class="text-h6">{{people.designation}}</span>
                      </v-card-title>
                      <v-card-text>
                        {{people.initialDetails}} {{people.moreDetails}}
                      </v-card-text>
                      <v-card-text>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red darken-1"
                          text
                          @click="people.dialog = false"
                        >
                          close
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.about-container{
  max-width: 1200px;
  margin: 0 auto;
}

.about-info{
  padding: 20px;
  margin: 20px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  border-radius: 15px;
}

.about-flex-box-holder{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.about-card {
    position: relative;
    width: 250px;
    margin: 30px 80px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
}

.about-img-holder{
  margin: 0 auto;
}

.about-card-img{
  width: 200px;
}

.text-holder{
  text-align: center;
}

.mar-top-15{
  margin-top: 15px;
}

.download-a{
  text-decoration: none;
  display: flex;
  align-content: center;
}

.pop-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.pop-flex img{
  max-width: 300px;
  width: auto;
}


@media screen and (max-width:768px) {

  .about-card {
    margin: 30px;
  }

  .pop-flex{
    flex-direction: column;
    align-items: center;
  }

  .pop-flex img{
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "about",
  data () {
      return {
        mainInfo: {
            details1:"Ploutus Holdings LLC is a dynamic and multifaceted international organization with a plethora of services and years of experience. We lay down strong foundations for a better tomorrow, providing profitable solutions to the toughest challenges. From Kenya to Sri Lanka, we have a long list of successfully completed projects. Ploutus Holdings LLC has many different subsidiaries associated with it, with projects including contracting services, telecommunications services, business IT solutions for government and commercial clients, tea production and distribution, real estate development, renewable power generation, health care projects, CSR projects, and more.",
            details2:"Ploutus Holdings LLC is a global Infrastructure Development and Finance company. We work with clients worldwide in various industries and in diverse capacities to design, construct, and maintain their capital and infrastructure projects. Our work is focused on creating collaborative partnerships in the developing countries of Africa, the Caribbean, and Asia.",
            details3:"Our value proposition is to provide turnkey solutions to the governments that we work with, including sourcing the finances needed for the projects that we undertake.",
        },
        panels:[
          {
            id:1,
            heading:"Which sectors do we work in?",
            details:"Infrastructure, Telecommunications, IT & AI, Renewable & Green Energy, Food Trading, Precious Metals Trading, Health Care, Finance, Oil & Gas, and Distribution.",
          },
          {
            id:2,
            heading:"Which countries do we work in?",
            details:"USA, Brazil, Ghana, Guinea, Uganda, Kenya, Qatar, UAE, India, Sri Lanka, Singapore, and Hong Kong.",
          },
          {
            id:3,
            heading:"What makes us different?",
            details:"We create business synergies around the world by bringing different people together for a common purpose. We are experts at gathering requirements, contacts, approvals, technology, project management, and investments for all our projects around the world.",
          },
        ],
        board:[
          {
            id:1,
            img:"sanoj.png",
            name:"Sanoj (OJ) Dhamindranath",
            designation:"CEO, PLOUTUS HOLDINGS, LLC",
            dialog: false,
            initialDetails:"Sanoj has over 20 years of progressive executive management experience. He oversees the execution of Ploutus’ mission, vision, and strategy for the group of companies. In addition to leading the overall financial and operational direction of the Group, he manages key client relationships and leads select endeavors in the areas of finance, construction and infrastructure, technology, oil and gas, renewable energy, mobile telecommunication, commodity trading, food distribution, consultancy services, mining, precious metals, health, travel and tourism.",
            moreDetails:"“OJ” has held superior operating roles in both private and publicly traded companies and has extended his business endeavors outside of the USA to the African & Asian continents and the Caribbean islands. OJ has developed a good network of investors, business leaders, attorneys, banks and technology partners to his network. He’s focused on impacting future generations by developing projects that impact lives, reduce poverty, and combat disease."
          },
          {
            id:2,
            img:"naveen.png",
            name:"Naveen Weerasena",
            designation:"DIRECTOR BUSINESS DEVELOPMENT",
            dialog: false,
            initialDetails:"A high-impact professional leader with over 25 years of management experience, project management, business analysis, finance & budgeting, and multi-domain knowledge. He has years of experience in the information technology field such as IT infrastructure, data & VOIP networks, IT security systems, centralized & decentralized server management.",
            moreDetails:"Naveen believes in building strong relationships with clients resulting in exceeding sales goals and receiving corporate recognition. Heaps of experience in dealing with high network individuals from different regions globally, executing apotheosis of investment deals under his pocket. With his data-driven and highly informed decision-making capabilities, he establishes strategic mutually beneficial partnerships."
          },
          {
            id:3,
            img:"anthony.png",
            name:"Anthony Muse",
            designation:"PRESIDENT, PLOUTUS OIL & GAS, LLC, PLOUTUS GLOBAL GENERAL TRADING LLC, AND BOUSSARD USA, LLC.",
            dialog: false,
            initialDetails:"As a young boy growing up on the streets of Baltimore City, C. Anthony Muse learned early that to go anywhere in this life, you need courage, determination, and a resilient spirit. Having never known his biological father and having been separated as a pre-teen from his biological mother, left virtually alone and on his own, young Anthony found himself shuffled from one foster home and foster family to another. The turmoil in his life led him on a journey through 11 different foster homes. Kicked out of three public school systems, Muse went on to earn three degrees: a Bachelors from Morgan State University, a Masters from Wesley Seminary, and a Doctorate from Howard University.",
            moreDetails:"Muse is married to Pat Lawson Muse, a news anchor for NBC-4 TV in Washington, D.C. Muse has more than 25 years of experience in community, political, and business legislation, policy, and development. He has served as a state ethics commissioner, ensuring honesty and integrity among those holding elective office. C. Anthony Muse served one 4-year term in the Maryland House of Delegates and 12 years in the Maryland State Senate. Muse has served on the Senate Finance Committee, working and writing legislation and policy focusing on the areas of energy. He also has years of experience in the areas of electricity, gas and oil. He has served on the Senate Judicial Proceedings Committee, Finance Healthcare, and Delivery Committee and has chaired the Joint Committee on Fair Practices."
          },
                    {
            id:4,
            img:"craig.png",
            name:"Craig Cannonier",
            designation:"BOARD ADIVSOR, FORMER PRIME MINISTER OF BERMUDA",
            dialog: false,
            initialDetails:"An exceptional leader with a surfeit of achievements who has served as the former Prime Minister of Bermuda. Among his attainments, he is a businessman mainly involved in Oil and gas, renewable energy, pharmaceutical, construction and mining. He is vastly influential in so many reputable business circles among North and South America.",
            moreDetails:"Versed in developing and executing company’s business strategies, providing advice to the board and chairperson. He is a great addition to our team who can successfully develop alliances between head departments to effectively drive growth and achieve goals. Dedicated to building strong teams within an organization through motivation and strong development."
          },
        ],
      }
  },
  mounted(){
	
    }
}
</script>